<template>
    <div>
      <div>
        <el-form layout="inline">
          <el-row :gutter="24">
            <el-col :md="6" :sm="8" v-for="(value,code) in roleList" v-bind:key="code">
              <el-button
               v-if="value.seniorStatus!='2'"
               :disabled="value.seniorStatus=='3'"
                placeholder
                style="width:60%"
                v-model="queryParam.fundName"
                @click="getCouponInfoButton(value)"
              >{{value.name}}</el-button>
            </el-col>
          </el-row>
        </el-form>
      </div>
  
      <div style="margin: 100px;">
        <el-link
          :disabled="this.clickUrl1==null"
          :href="this.clickUrl1"
          target="_blank"
          style="font-family:arial;font-size:30px;text-align:center"
        >链接1--点击跳转</el-link>
        <br />
        <br />
        <el-link
          :disabled="this.clickUrl2==null"
          :href="this.clickUrl2"
          target="_blank"
          style="font-family:arial;font-size:30px;text-align:center"
        >链接2--点击跳转</el-link>
        <br />
        <br />
        <el-link
          :disabled="this.clickUrl3==null"
          :href="this.clickUrl3"
          target="_blank"
          style="font-family:arial;font-size:30px;text-align:center"
        >链接3--点击跳转</el-link>
        <br />
        <br />
        <div class="demo-image__preview">
          <el-image style="width: 200px; height: 200px" :src="this.pathUrl" :preview-src-list="PathSrcList"></el-image>
        </div>
      </div>
    </div>
  </template>
  <script>
  import { getCouponListApi } from "@/api/system/mune/menuCoupon.js";
  import { getDataApi } from "@/api/coupon/juheApi.js";
  export default {
    props: ['homeMenus'],
    data() {
      return {
        queryParam: {},
        roleList: [],
      };
    },
    mounted() {
      this.getMuneList();
    },
    //防止第一次点击失效
    created() {
      this.getMuneList();
    },
    methods: {
      getMuneList() {
        var query = {
          type: 3,
          resources: this.homeMenus.code,
          productSource:this.homeMenus.productSource
        };
        getCouponListApi(query).then(res => {
          this.roleList = res.result;
        });
      },
      clearUrl() {
      },
      getCouponInfoButton(val) {
        this.clearUrl();
        var query = {
          menuCode: val.code,
          activityId: val.typeParam,
          dataSign:val.dataSign,
          // consName:'天蝎座',
          // type:'month'
        };
        getDataApi(query).then(res => {
            console.log(res)
        });
      }
    }
  };
  </script>
  <style scoped>
  </style>